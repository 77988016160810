import React from "react"
import { graphql } from "gatsby"
import {
  AppBar,
  Breadcrumb,
  Footer,
  Layout,
  SEO,
  SnsShare,
} from "../components"
import { About } from "../views"
import { PagesAboutQuery } from "../../types/graphql-types"

type Props = { data: PagesAboutQuery }

const Component: React.FC<Props> = ({ data }: Props) => {
  const title: string = "このサイトについて"
  const slug: string = "neaboutws"

  const service = data.site.siteMetadata.service
  const social = data.site.siteMetadata.social

  return (
    <Layout>
      <SEO title={title} slug={slug} />
      <SnsShare title={title} slug={slug} />
      <AppBar />
      <Breadcrumb
        showNav={true}
        itemList={[
          { name: "トップ", slug: "" },
          { name: title, slug: slug },
        ]}
      />
      <About
        service={{
          ecommerce: service.ecommerce,
          gunman: service.gunman,
          about: service.about,
        }}
      />
      <Footer slug="/" copyright={{ publishDate: new Date() }} />
    </Layout>
  )
}

export default Component

export const query = graphql`
  query PagesAbout {
    site {
      siteMetadata {
        social {
          facebook
          twitter
        }
        service {
          gunman
          ecommerce
          about
        }
      }
    }
  }
`
